import { Dropdown } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { ConnectedProps, connect } from 'react-redux';

import { loadStatus } from 'data/actions/phases';
import { load } from 'data/actions/roleSummaries';
import { getSelectedPhase, getTeamSummaries, isLoadingTeamRoles } from 'data/reducers';
import { useEffect, useState } from 'react';
import withRouter from 'routes/withRouter';
import { isNavigator } from 'utils';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import TeamsAddTable from './components/TeamsAddTable';
import TeamsAddButton from './components/TeamsAddTable/TeamsAddButton';
import TeamsRemoveButton from './components/TeamsAddTable/TeamsRemoveButton';
import TeamsLayout from './components/TeamsLayout';
import { Team } from './shared/types';

type OwnProps = {};

const mapDispatchToProps = { load, loadStatus };

const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  // @ts-ignore
  selectedPhase: getSelectedPhase(state, ownProps)?.toJS?.(),
  // @ts-ignore
  teams: getTeamSummaries(state, ownProps),
  teamsLoading: isLoadingTeamRoles(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type TeamsAddProps = PropsFromRedux & OwnProps;

const TeamsAdd = ({ load, teams, selectedPhase, loadStatus, teamsLoading }: TeamsAddProps) => {
  const [areTeamsLoaded, teamsLoadComplete] = useBooleanFlag(false);
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);

  async function reloadTeams() {
    // @ts-ignore
    await load('teams', selectedPhase?.id).then(() => teamsLoadComplete());
    loadStatus(selectedPhase?.id);
    setSelectedTeams([]);
  }

  useEffect(() => {
    if (selectedPhase?.id) reloadTeams();
  }, [selectedPhase?.id]);

  return (
    <TeamsLayout
      toolbar={
        isNavigator() ? null : (
          <div className="text-end flex-grow-1 d-flex flex-row align-items-start justify-content-end">
            <TeamsAddButton
              phaseId={selectedPhase?.id}
              onTeamsAdded={reloadTeams}
              activeTeams={teams?.length}
              areTeamsLoaded={areTeamsLoaded}
            />
            <Dropdown>
              <Dropdown.Toggle className="pe-3 flex-nowrap" id="actions-dropdown" variant="success">
                <i className={cx('fa-solid fa-cog')} />
                Actions
                <i className="fa-solid fa-angle-down ms-3" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="mt-1" renderOnMount={true} style={{ width: 250 }}>
                <div className="pt-2 pt-1">
                  <TeamsRemoveButton
                    phaseId={selectedPhase?.id}
                    selectedTeams={selectedTeams}
                    onTeamsRemoved={reloadTeams}
                  />
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )
      }
    >
      <TeamsAddTable
        loading={teamsLoading}
        data={teams}
        selectedTeams={selectedTeams}
        onTeamSelectionChange={setSelectedTeams}
        isPanelMatching={selectedPhase ? selectedPhase.matching_mode === 'panel_matching' : false}
      />
    </TeamsLayout>
  );
};

export default withRouter(connector(TeamsAdd));
