import { useEffect } from 'react';
import { connect } from 'react-redux';

import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { load } from 'data/actions/roleSummaries';
import {
  getActiveTeamCount,
  getEventFromURL,
  getInactiveTeamCount,
  getSelectedPhase,
  getSelectedPhaseIdForCurrentEvent,
  getTeamSummaries,
  isLoadingRoleSummaries,
  isLoadingTeamRoles,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { isNavigator } from 'utils';

import TeamAssignmentsToolbar from './components/TeamAssignmentsToolbar';
import { TeamsAssigmentsTable } from './components/TeamsAssigmentsTable';
import TeamsLayout from './components/TeamsLayout';

const ROLE = 'teams';

const TeamAssignments = ({
  selectedPhaseId,
  loading,
  teamsLoading,
  teams,
  activeCount,
  inactiveCount,
  params,
  selectedPhase,
  load,
}) => {
  const loadTeams = () => {
    load(ROLE, selectedPhaseId);
  };

  useEffect(() => {
    loadTeams();
  }, [selectedPhaseId, load]);

  return (
    <TeamsLayout toolbar={isNavigator() ? null : <TeamAssignmentsToolbar reload={loadTeams} />}>
      <TeamsAssigmentsTable
        loading={loading || teamsLoading}
        data={teams}
        eventId={params.event_id}
        activeCount={activeCount}
        inactiveCount={inactiveCount}
        teamsLoading={teamsLoading}
        isPanelMatching={selectedPhase?.matching_mode === 'panel_matching'}
      />
    </TeamsLayout>
  );
};

export default withRouter(
  connect(
    (state, ownProps) => {
      const event = getEventFromURL(state, ownProps)?.toJS?.();
      const teams = getTeamSummaries(state, ownProps);
      const activeCount = getActiveTeamCount(state, ownProps);
      const inactiveCount = getInactiveTeamCount(state, ownProps);
      const loading = isLoadingRoleSummaries(state);
      const teamsLoading = isLoadingTeamRoles(state);
      const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);
      const selectedPhase = getSelectedPhase(state, ownProps)?.toJS?.();
      return {
        event,
        teams,
        activeCount,
        inactiveCount,
        loading,
        teamsLoading,
        selectedPhaseId,
        selectedPhase,
      };
    },
    {
      load,
      notifyError,
      notifySuccess,
    },
  )(TeamAssignments),
);
