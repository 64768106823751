import { isRejectedWithValue } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import GenericModal from 'components/GenericModal';
import { error as showError, success as showSuccess } from 'data/actions/notifications';
import { load } from 'data/actions/roleSummaries';
import { deleteNotification } from 'data/features/notifications';
import {
  getActiveTeamCount,
  getEventFromURL,
  getInactiveTeamCount,
  getPhase,
  getSelectedPhase,
  getSelectedPhaseIdForCurrentEvent,
  getTeamSummaries,
  isLoadingRoleSummaries,
  isLoadingTeamRoles,
} from 'data/reducers';
import withRouter from 'routes/withRouter';
import { isNavigator, isTeamLead } from 'utils';
import { teamsCommunications } from 'utils/notifications';
import { organizerNav } from 'utils/urls';

import CancelNotificationModal from './components/CancelNotificationModal.jsx';
import CommunicationsNotifyDropdown from './components/communications/CommunicationsNotifyDropdown';
import TeamsLayout from './components/TeamsLayout';
import { TeamsManageTable } from './components/TeamsManageTable';
import Triage from './components/Triage/Triage';

function TeamManage({
  params,
  selectedPhaseId,
  load,
  teams,
  teamsLoading,
  phase,
  selectedPhase,
  deleteNotification,
  showError,
  showSuccess,
}) {
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isEmptyTeamsModalOpened, setIsEmptyTeamsModalOpened] = useState(false);
  const [isOptionDisabledModalOpened, setIsOptionDisabledModalOpened] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [rowsWithScheduledNotifications, setrowsWithScheduledNotifications] = useState([]);

  const handleOptionDisabledClose = () => setIsOptionDisabledModalOpened(false);
  const handleEmptyTeamsClose = () => setIsEmptyTeamsModalOpened(false);
  const reloadTeams = () => load('teams', selectedPhaseId);

  useEffect(() => {
    reloadTeams();
  }, [selectedPhaseId, phase]);

  useEffect(() => {
    const filteredTeams = selectedTeams.filter((team) => team.latestMessage?.send_at);
    setrowsWithScheduledNotifications(filteredTeams);
  }, [selectedTeams]);

  const handleScheduledClick = (notification) => {
    setSelectedNotification(notification);
    setShowCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
    setSelectedNotification(null);
  };

  const handleConfirmDelete = async (deleteForAll) => {
    const result = await deleteNotification({ id: selectedNotification.id, batch: deleteForAll });

    if (isRejectedWithValue(result)) {
      const payload = result.payload;
      showError(payload?.response.message || 'Failed to cancel the notification');
      return;
    }
    showSuccess('Notification cancelled successfully');

    reloadTeams();
    handleCancelModalClose();
  };

  return (
    <TeamsLayout
      toolbar={
        isNavigator() || isTeamLead() ? null : (
          <div className="text-end flex-grow-1 d-flex justify-content-end mb-3 me-3 align-items-center">
            <Triage entity={'teams'} selected={selectedTeams} reload={reloadTeams} />
            <CommunicationsNotifyDropdown
              afterSend={() => {
                setSelectedTeams([]);
                setTimeout(reloadTeams, 2e3);
              }}
              communications={teamsCommunications}
              extraData={{
                teamProfileIds: selectedTeams.map((j) => j.id),
                rowsWithScheduledNotifications,
              }}
              sendTo="teams"
              shouldSend={(template) => {
                const isValidSelection = Boolean(selectedTeams.length);
                if (
                  isValidSelection &&
                  !phase.get('feedback_enabled') &&
                  template?.slug === 'team_feedback_available'
                ) {
                  setIsOptionDisabledModalOpened(true);
                  return false;
                }
                if (!isValidSelection) {
                  setIsEmptyTeamsModalOpened(true);
                  return false;
                }
                return true;
              }}
            />
          </div>
        )
      }
    >
      {!isNavigator() && !isTeamLead() && (
        <>
          <GenericModal
            show={isEmptyTeamsModalOpened}
            confirmButton="Accept"
            title="No teams selected"
            body="Please select some teams from the table to perform the action"
            onConfirm={handleEmptyTeamsClose}
            onCancel={handleEmptyTeamsClose}
          />
          <GenericModal
            show={isOptionDisabledModalOpened}
            confirmButton="Acknowledge"
            title="Feedback not available to teams"
            body={
              <>
                First enable the <strong>Feedback & scores available to teams</strong> option on the{' '}
                <Link to={organizerNav.settings(params.event_id)}>settings screen</Link>
              </>
            }
            onConfirm={handleOptionDisabledClose}
            onCancel={handleOptionDisabledClose}
          />
          <CancelNotificationModal
            show={showCancelModal}
            onCancel={handleCancelModalClose}
            onConfirmDelete={handleConfirmDelete}
          />
        </>
      )}

      <TeamsManageTable
        loading={teamsLoading}
        data={teams}
        eventId={params.event_id}
        teamsLoading={teamsLoading}
        selectedTeams={selectedTeams}
        onSelectedTeamsChange={setSelectedTeams}
        isPanelMatching={selectedPhase ? selectedPhase.matching_mode === 'panel_matching' : false}
        onScheduledClick={handleScheduledClick}
      />
    </TeamsLayout>
  );
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const event = getEventFromURL(state, ownProps)?.toJS?.();
      const teams = getTeamSummaries(state, ownProps);
      const activeCount = getActiveTeamCount(state, ownProps);
      const inactiveCount = getInactiveTeamCount(state, ownProps);
      const loading = isLoadingRoleSummaries(state);
      const teamsLoading = isLoadingTeamRoles(state);
      const selectedPhase = getSelectedPhase(state, ownProps)?.toJS?.();
      const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);
      const phase = getPhase(state, selectedPhaseId);
      return {
        event,
        teams,
        activeCount,
        inactiveCount,
        loading,
        teamsLoading,
        selectedPhaseId,
        selectedPhase,
        phase,
      };
    },
    {
      load,
      deleteNotification,
      showError,
      showSuccess,
    },
  )(TeamManage),
);
