import { Button } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { error as notifyError, success as notifySuccess } from 'data/actions/notifications';
import { load, updateManyRoleStatuses, updateRoleStatus } from 'data/actions/roleSummaries';
import {
  getActiveJudgeCount,
  getInactiveJudgeCount,
  getJudgeSummaries,
  getSelectedPhase,
  getSelectedPhaseIdForCurrentEvent,
  isLoadingJudgeRoles,
  isLoadingRoleSummaries,
} from 'data/reducers';
import { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'routes/withRouter';
import { isNavigator } from 'utils';
import JudgeAssignmentsToolbar from './components/JudgeAssignmentsToolbar';
import { JudgesAssignmentsTable } from './components/JudgesAssignmentsTable';
import JudgesLayout from './components/JudgesLayout';

const ROLE = 'judges';

class JudgeAssignments extends Component {
  reload = () => {
    const { selectedPhaseId } = this.props;
    this.props.load(ROLE, selectedPhaseId);
  };

  componentDidMount() {
    this.reload();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPhaseId !== this.props.selectedPhaseId) {
      this.props.load(ROLE, nextProps.selectedPhaseId);
    }
  }

  toggleJudge = (item) => {
    const { selectedPhaseId, updateRoleStatus, notifyError, notifySuccess } = this.props;
    updateRoleStatus(selectedPhaseId, ROLE, item.id, !item.active).then((payload) => {
      const activated = item.active ? 'deactivated' : 'activated';
      return payload.error
        ? notifyError('Status could not be updated')
        : notifySuccess(`Judge ${activated}`);
    });
  };

  updateAllStatuses = (active) => {
    const { selectedPhaseId, updateManyRoleStatuses, notifyError, notifySuccess } = this.props;
    updateManyRoleStatuses(selectedPhaseId, ROLE, active).then((payload) => {
      const activated = active ? 'activated' : 'deactivated';
      return payload.error
        ? notifyError('Status could not be updated')
        : notifySuccess(`Judges ${activated}`);
    });
  };

  render() {
    const { judges, loading, judgesLoading, activeCount, inactiveCount, params, selectedPhase } =
      this.props;
    const allActive = judges.every((j) => j.active);

    return (
      <JudgesLayout
        toolbar={
          isNavigator() ? null : (
            <div className="text-end flex-grow-1">
              <JudgeAssignmentsToolbar reload={this.reload} />
              <Button
                onClick={() => this.updateAllStatuses(!allActive)}
                variant="success"
                className="ms-2"
              >
                <i
                  className={cx('fa-solid', {
                    'fa-users-slash': allActive,
                    'fa-users': !allActive,
                  })}
                />
                {!allActive ? 'Activate' : 'Deactivate'} All
              </Button>
            </div>
          )
        }
      >
        <JudgesAssignmentsTable
          loading={loading || judgesLoading}
          activeCount={activeCount}
          eventId={params.event_id}
          inactiveCount={inactiveCount}
          judgesLoading={judgesLoading}
          data={judges}
          onActiveChange={this.toggleJudge}
          isPanelMatching={selectedPhase?.matching_mode === 'panel_matching'}
        />
      </JudgesLayout>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const judges = getJudgeSummaries(state, ownProps);
      const activeCount = getActiveJudgeCount(state, ownProps);
      const inactiveCount = getInactiveJudgeCount(state, ownProps);
      const selectedPhaseId = getSelectedPhaseIdForCurrentEvent(state, ownProps);
      const selectedPhase = getSelectedPhase(state, ownProps)?.toJS?.();
      return {
        loading: isLoadingRoleSummaries(state),
        judgesLoading: isLoadingJudgeRoles(state),
        judges,
        activeCount,
        inactiveCount,
        selectedPhaseId,
        selectedPhase,
      };
    },
    {
      load,
      updateRoleStatus,
      updateManyRoleStatuses,
      notifyError,
      notifySuccess,
    },
  )(JudgeAssignments),
);
