import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { filterResults } from 'components/Tables/filteringHelpers';
import Table from 'components/Tables/Table';
import { FiltersState } from 'components/Tables/types';
import { isNavigator, isTeamLead } from 'utils';

import Controls from './components/Controls';
import { columns } from './components/Definition';
import { filterByStatus, searchableFields } from './components/helpers';
import { Team } from '../../shared/types';
import { useTranslation } from 'react-i18next';

export type TeamManageTableProps = {
  eventId: string;
  data: Team[];
  selectedTeams: Team[];
  onSelectedTeamsChange: (teams: Team[]) => void;
  isPanelMatching: boolean;
  onScheduledClick: (notification: any) => void;
  loading: boolean;
};

const TeamsManageTable = ({
  eventId,
  data,
  selectedTeams,
  onSelectedTeamsChange,
  isPanelMatching,
  onScheduledClick,
  loading,
}: TeamManageTableProps) => {
  const { t } = useTranslation();
  const [globalFilter, setGlobalFilter] = useState<FiltersState>({
    textFilter: '',
    otherFilters: [],
  });
  const [rowSelection, setRowSelection] = useState({});
  const mainEntity = { eventId, mainEntity: 'teams' };

  // Set selection for external component
  useEffect(() => {
    const selectedRows = Object.keys(rowSelection).map((key) => (data as any)[key]);
    onSelectedTeamsChange(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  // Selection cleared out outside this component
  useEffect(() => {
    if (selectedTeams.length === 0 && selectedTeams.length !== Object.keys(rowSelection).length) {
      setRowSelection({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeams]);

  // Table Filtering
  const handleTextFilterChange = (textFilter: string) => {
    setGlobalFilter({ ...globalFilter, textFilter });
  };

  const handleOtherFiltersChange = (otherFilters: any[]) => {
    setGlobalFilter({ ...globalFilter, otherFilters: [...otherFilters] });
  };

  // Row selection
  const enableRowSelection = () => {
    return !isNavigator();
  };

  const tableModel = useReactTable({
    data,
    columns,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection,
    meta: {
      mainEntity,
      onScheduledClick,
    },
    state: {
      globalFilter,
      rowSelection,
      columnVisibility: {
        id: !isNavigator() && !isTeamLead(),
        Panel: isPanelMatching,
        Prefix: !isPanelMatching,
      },
    },
    globalFilterFn: filterResults(
      searchableFields.filter((_, i) => (isPanelMatching ? i !== 1 : i !== 2)), // Updated to use isPanelMatching
      filterByStatus,
    ),
  });

  return (
    <>
      <Controls
        textFilter={globalFilter.textFilter}
        data={data}
        filters={globalFilter.otherFilters}
        onOtherFiltersChange={handleOtherFiltersChange}
        onTextFilterChange={handleTextFilterChange}
      />
      <Table
        loading={loading}
        tableModel={tableModel}
        emptyContent={isTeamLead() ? t('teams.team_lead_empty') : undefined}
      />
    </>
  );
};

export default TeamsManageTable;
