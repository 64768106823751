import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import Table from 'components/Tables/Table';
import { FiltersState } from 'components/Tables/types';

import { filterResults } from 'components/Tables/filteringHelpers';
import Controls from './components/Controls';
import { matchColumns } from './components/Definition';
import { filterByStatus, searchableFields } from './components/helpers';
import { Judge } from 'screens/app/events/shared/types';

export type JudgesAssignmentsTableProps = {
  activeCount: number;
  data: Judge[];
  eventId: string;
  inactiveCount: number;
  onActiveChange: (record: Judge) => void;
  isPanelMatching: boolean;
  loading: boolean;
};

const JudgesAssignmentsTable = ({
  activeCount,
  data,
  eventId,
  inactiveCount,
  onActiveChange,
  isPanelMatching,
  loading,
}: JudgesAssignmentsTableProps) => {
  const [filter, setFilter] = useState<FiltersState>({ textFilter: '', otherFilters: [] });
  const [tableColumns, setTableColumns] = useState([...matchColumns]);

  const mainEntity = { eventId, mainEntity: 'judges' };
  const assignedEntity = { eventId, assignedEntity: 'teams' };

  useEffect(() => {
    setTableColumns(matchColumns.filter((_, i) => (isPanelMatching ? true : i !== 1)));
  }, [isPanelMatching]);

  // Table Filtering
  const handleTextFilterChange = (textFilter: string) => {
    setFilter({ ...filter, textFilter });
  };

  const handleOtherFiltersChange = (otherFilters: any[]) => {
    setFilter({ ...filter, otherFilters: [...otherFilters] });
  };

  const tableModel = useReactTable({
    data,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    meta: {
      onActiveChange,
      mainEntity,
      assignedEntity,
    },
    state: {
      globalFilter: filter,
    },
    globalFilterFn: filterResults(searchableFields, filterByStatus),
  });

  return (
    <>
      <Controls
        textFilter={filter.textFilter}
        data={data}
        filters={filter.otherFilters}
        onOtherFiltersChange={handleOtherFiltersChange}
        onTextFilterChange={handleTextFilterChange}
        info={`${activeCount} Active, ${inactiveCount} Inactive`}
      />
      <Table tableModel={tableModel} loading={loading} />
    </>
  );
};

export default JudgesAssignmentsTable;
